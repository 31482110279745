<template>
    <TransitionRoot appear :show="true" as="template">
        <Dialog as="div" @close="closeModal" class="relative z-40 font-notoSans">
            <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
                leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-black/35" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto font-poppins">
                <div class="flex font-notoKhmer min-h-full items-start justify-center p-4 text-center">
                    <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95">
                        <DialogPanel
                            class="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <DialogTitle as="h3"
                                class="text-2xl font-semibold leading-6 text-tertiary flex justify-between items-center">
                                <span v-if="!doc">Create new course category</span>
                                <span v-else>Update course category</span>
                                <span @click="closeModal" class="text-red-600 cursor-pointer">
                                    <font-awesome-icon size="lg" icon="fa-solid fa-circle-xmark" />
                                </span>
                            </DialogTitle>
                            <div class="mt-8">
                                <form @submit.prevent="handleSubmitCourse"
                                    class="my-4 grid gap-5 grid-cols-1 md:grid-cols-2">
                                    <div class="col-span-1 md:col-span-2">
                                        <label for="title" class="text-lg text-quaternary">Title <span
                                                class="text-red-600">*</span></label>
                                        <input v-model="title" class="form-control w-full" id="title" type="text"
                                            placeholder="Title" required>
                                    </div>
                                    <div class="flex flex-col">
                                        <label for="title" class="text-lg text-quaternary">Category <span
                                                class="text-red-600">*</span></label>
                                        <select v-model="categoryID" required
                                            class="form-control w-full px-[20px] py-[12px] rounded-lg cursor-pointer bg-white border-2 border-quaternary-low-light">
                                            <option value="" selected disabled>--Choose Category--</option>
                                            <option v-for="(doc, i) in courseCategories" :key="i" :value="doc.id">
                                                {{ doc.categoryName }}
                                            </option>

                                        </select>
                                    </div>

                                    <div>
                                        <label for="teacher-name" class="text-lg text-quaternary">Teacher name <span
                                                class="text-red-600">*</span></label>
                                        <input v-model="teacherName" class="form-control w-full" id="teacher-name"
                                            type="text" placeholder="Teacher name" required>
                                    </div>
                                    <div>
                                        <label for="price" class="text-lg text-quaternary">Price <span
                                                class="text-red-600">*</span></label>
                                        <input v-model.number="price" class="form-control w-full" id="price"
                                            type="number" placeholder="Price" required>
                                    </div>
                                    <div>
                                        <label for="discount" class="text-lg text-quaternary">Discount <span
                                                class="text-red-600"></span></label>
                                        <input v-model.number="discount" class="form-control w-full" id="discount"
                                            type="number" placeholder="Discount">
                                    </div>
                                    <div>
                                        <label for="start-date" class="text-lg text-quaternary ">Start date <span
                                                class="text-red-600"></span></label>
                                        <input v-model="startDate" class="form-control w-full cursor-pointer"
                                            id="start-date" type="date" placeholder="Start date" >
                                    </div>
                                    <div>
                                        <label for="end-date" class="text-lg text-quaternary ">End date <span
                                                class="text-red-600"></span></label>
                                        <input v-model="endDate" class="form-control w-full cursor-pointer"
                                            id="end-date" type="date" placeholder="End date" >
                                    </div>
                                    <div>
                                        <label for="image" class="text-lg text-quaternary">Image<span
                                                class="text-red-600"></span></label>
                                        <div v-if="!image.src">
                                            <label @change="handleUploadImage">
                                                <div
                                                    class="min-w-[720px] max-w-[720px] max-h-[400px] min-h-[400px] ">
                                                
                                                    <img src="https://placehold.co/720x400" alt="">
                                                </div>

                                                <input type="file" class="hidden" />
                                            </label>
                                        </div>
                                        <div v-else>
                                            <div class="relative group min-w-[720px] max-w-[720px] max-h-[400px] min-h-[400px]  flex justify-center overflow-hidden ">
                                                <img class="  p-px border cursor-text"
                                                    :src="image.url ? image.url : image.src" alt="image is broken!"  />
                                                <span @click="handleRemoveImage(index)"
                                                    class="cursor-pointer absolute top-1 right-1 bg-red-600 text-white hover:shadow hover:border w-5 h-5 rounded-full flex items-center justify-center">
                                                    <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                        viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="fileError" class="text-red-500 text-xs">{{ fileError }}</div>
                                    </div>
                                    <div class="col-span-2">
                                        <label for="description" class="text-lg text-quaternary">Description<span
                                                class="text-red-600">*</span></label>
                                        <!-- <textarea v-model="description" class="form-control w-full" id="description"
                                            placeholder="Write your course description here!" required></textarea>
                                             -->
                                        <CustomCkEditor @onListenToData="getDataFromCkEditor" :data="description" />
                                        <p v-if="description == ''" class="text-blood">Description is required</p>
                                    </div>

                                    <div class="col-span-2">
                                        <label for="reCap" class="text-lg text-quaternary">Summarize <span
                                                class="text-red-600">*</span></label>
                                        <input v-model="summarize" class="form-control w-full" id="reCap"
                                            type="text" placeholder="Summarize" required>
                                    </div>

                                    <div class="flex space-x-3">
                                        <div
                                            class="col-span-1 md:col-span-2 space-x-2 flex justify-start items-center text-sm md:text-base lg:text-lg">
                                            <Switch v-model="status" :class="status ? 'bg-primary' : 'bg-gray-200'"
                                                class="relative inline-flex h-6 w-11 items-center rounded-full">
                                                <span :class="status ? 'translate-x-6' : 'translate-x-1'"
                                                    class="inline-block h-4 w-4 transform rounded-full bg-white transition" />
                                            </Switch>

                                            <span>{{ status ? 'Active' : 'Inactive' }}</span>

                                        </div>

                                        <div class="flex items-center">
                                            <input v-model="isOutStanding" id="outS" type="checkbox"
                                                class="input-checkbox cursor-pointer">
                                            <label for="outS" class="ml-2 select-none">OutStanding</label>
                                        </div>

                                    </div>


                                    <div class="flex col-span-1 md:col-span-2 items-center justify-end">
                                        <button class="btn btn-primary rounded-full">{{ doc ? 'Update' :
                                            'Add'}}</button>
                                    </div>
                                </form>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { getCollectionQuery } from '@/composables/getCollection';
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    Switch

} from '@headlessui/vue'
import { onMounted, ref, watch } from 'vue';
import { orderBy, serverTimestamp, where } from 'firebase/firestore';
import useStorage from '@/composables/useStorage';
import useCollection from '@/composables/useDocument';
import CustomCkEditor from '@/components/admin/CustomCkEditor.vue';
import { push } from 'notivue';
import moment from 'moment';
// import moment from 'moment';
export default {
    emits: ['closeModal', "handleAddSuccess"],
    props: ['doc'],
    components: {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle,
        Switch,
        CustomCkEditor

    },

    setup(props, { emit }) {
        const title = ref('');
        const categoryID = ref('');
        const teacherName = ref('');
        const price = ref('');
        const discount = ref('');
        const startDate = ref('');
        const endDate = ref('');
        const description = ref('');
        const status = ref(true);
        const courseCategories = ref([]);
        const image = ref({});
        const file = ref('');
        const fileError = ref("");
        const timeStamp = ref("");
        const isOutStanding=ref(false);
        const summarize=ref("");

 
        timeStamp.value = serverTimestamp();

        const types = ["image/png", "image/jpg", "image/jpeg", "image/svg"];

        const closeModal = () => {
            emit('closeModal', false);
        }

        const getDataFromCkEditor = (data) => {
            description.value = data
        }

        const handleUploadImage = (e) => {


            const selected = e.target.files[0];
            const limitedSize = 1024 * 1024;


      
            if (selected.size > limitedSize) {
                fileError.value = 'Size image must be equal or less than 1MB';
                file.value = null;
            } else {
                if (selected && types.includes(selected.type)) {


                    image.value = {
                        name: selected.name,
                        src: URL.createObjectURL(selected),
                    };
                    file.value = selected;
                    fileError.value = null;
                } else {
                    fileError.value = 'Only file of type jpg, jpeg, svg, png are allowed.';
                    file.value = null;
                }
            }
        }

     
        const handleRemoveImage = async () => {
       
                image.value = {};
                file.value = [];
            
        }

        const queryDoc = [where("status", "==", true), orderBy("createdAt", "desc")];
        const fetchCourseCategories = async () => {
            await getCollectionQuery("course-categories", queryDoc, (data) => {
                courseCategories.value = data;
            }, null, true);
        }


        const startDateTimeStamp = ref(null);

        watch(startDate, (newV) => {
            if (newV) {

                const currentDate = new Date();
                // Get the time part of the current date and format it as HH:mm
                const currentTime = `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()} GMT${currentDate.getTimezoneOffset() > 0 ? '-' : '+'}${Math.abs(currentDate.getTimezoneOffset() / 60).toString().padStart(2, '0')}${Math.abs(currentDate.getTimezoneOffset() % 60).toString().padStart(2, '0')}`;
                // Combine the date part from the input with the time part from the current time
                const dateTimeString = `${newV} ${currentTime}`;
                // Create a Date object from the combined date and time
                const combinedDateTime = new Date(dateTimeString);

                startDateTimeStamp.value = combinedDateTime;
              
            }else{
                startDateTimeStamp.value = null;
            }
        });

        const endDateTimeStamp = ref(null);
        watch(endDate, (newV) => {
            if (newV) {

                const currentDate = new Date();
                // Get the time part of the current date and format it as HH:mm
                const currentTime = `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()} GMT${currentDate.getTimezoneOffset() > 0 ? '-' : '+'}${Math.abs(currentDate.getTimezoneOffset() / 60).toString().padStart(2, '0')}${Math.abs(currentDate.getTimezoneOffset() % 60).toString().padStart(2, '0')}`;
                // Combine the date part from the input with the time part from the current time
                const dateTimeString = `${newV} ${currentTime}`;
                // Create a Date object from the combined date and time
                const combinedDateTime = new Date(dateTimeString);

                endDateTimeStamp.value = combinedDateTime;
             
            }else{
                endDateTimeStamp.value = null;
            }
        })


        const { uploadImage } = useStorage();
        const { addDocs, updateDocs } = useCollection("courses");
        const handleSubmitCourse = async () => {

            if (props.doc) {

                const data = {
                    title: title.value,
                    titleLowerCase: title.value.toLowerCase(),
                    categoryID: categoryID.value,
                    teacherName: teacherName.value,
                    price: price.value,
                    discount: discount.value,
                    startDate: startDateTimeStamp.value,
                    endDate: endDateTimeStamp.value,
                    description: description.value,
                    status: status.value,
                    summarize:summarize.value,
                    isOutStanding:isOutStanding.value ? isOutStanding.value : false,
                    dateOutStanding:isOutStanding.value === true ? timeStamp.value : null,
                }
                

                if (file.value) {
                    const imageUrl = await uploadImage(`courses/${file.value.name}`, file.value)
                    data.imageUrl = imageUrl;
                    const success = await updateDocs(props.doc.id, data);
                    if (success) {
                        push.success("Update course successfully!");
                        emit("closeModal", false);
                    } else {
                        push.error("Something went wrong! please try again!")
                    }
                } else {
                    data.imageUrl = props.doc.imageUrl;
                    const success = await updateDocs(props.doc.id, data);
                    if (success) {
                        push.success("Update course successfully!");
                        emit("closeModal", false);
                    } else {
                        push.error("Something went wrong! please try again!")
                    }
                }
            } else {
                const data = {
                    title: title.value,
                    titleLowerCase: title.value.toLowerCase(),
                    categoryID: categoryID.value,
                    teacherName: teacherName.value,
                    price: price.value,
                    discount: discount.value,
                    startDate: startDateTimeStamp.value,
                    endDate: endDateTimeStamp.value,
                    description: description.value,
                    status: status.value,
                    summarize:summarize.value,
                    isOutStanding:isOutStanding.value ? isOutStanding.value :false,
                    dateOutStanding:isOutStanding.value ===true ? timeStamp.value : null,
                    createdAt: timeStamp.value

                }

                try {
                    data.createdAt = timeStamp.value
                    if (file.value) {
                        const imageUrl = await uploadImage(`courses/${file.value.name}`, file.value);
                        data.imageUrl = imageUrl;
                        const success = await addDocs(data);
                        if (success) {
                            emit("handleAddSuccess");
                            emit("closeModal", false);
                        } else {
                            push.error("Something went wrong! please try again!")
                        }
                    } else {
                        data.imageUrl = null;
                        const success = await addDocs(data);
                        if (success) {
                            emit("handleAddSuccess");
                            emit("closeModal", false);
                        } else {
                            push.error("Something went wrong! please try again!")
                        }
                    }
                } catch (err) {
                    fileError.value = err.message;
                }
            }
        }



        onMounted(() => {
            fetchCourseCategories();
            if (props.doc) {
                title.value = props.doc.title
                categoryID.value = props.doc.categoryID
                teacherName.value = props.doc.teacherName
                price.value = props.doc.price
                discount.value = props.doc.discount;
                isOutStanding.value=props.doc.isOutStanding;
                summarize.value=props.doc.summarize;
                const formattedStartDate=ref(null);
                const formattedEndDate =ref(null);
                if(props.doc.startDate !=null){
                       formattedStartDate.value= moment(props.doc.startDate?.toDate()).format('YYYY-MM-DD');
                }
             

                if(props.doc.endDate !=null){
                formattedEndDate.value= moment(props.doc.endDate?.toDate()).format('YYYY-MM-DD');
                }
             


                // Assuming startDate and endDate are input elements
                startDate.value = formattedStartDate.value;
                endDate.value = formattedEndDate.value;


                description.value = props.doc.description
                status.value = props.doc.status,

                image.value.src= props.doc.imageUrl
                console.log("immage url",image)
            }

        })

        return {
            summarize,
            isOutStanding,
            title,
            categoryID,
            teacherName,
            price,
            discount,
            startDate,
            endDate,
            description,
            status,
            courseCategories,
            fileError,
            image,
            handleSubmitCourse,
            closeModal,
            handleUploadImage,
            handleRemoveImage,
            getDataFromCkEditor
        }
    }
}
</script>