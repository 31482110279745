
import { createApp } from 'vue'
import App from './App.vue'
import routerClient from "./router";
import routerAdmin from './router/admin';
import { projectAuth } from '@/firebase/config';
// tailwindcss
import './css/index.css'
import VueNumerals from 'vue-numerals';

import {
  
  setPersistence,
  browserLocalPersistence,

} from "firebase/auth";


//  Notification component
import { createNotivue } from 'notivue';
const notivue = createNotivue({
    position: 'top-center',
    limit: 1,
    enqueue: false,
    notifications: {
      global: {
        duration: 2000
      }
    }
  })  

//fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faDashboard, faChevronRight, 
         faChevronDown, faList,faArrowRight,
         faTags, faUsers, faCircleInfo,faHouse,
         faArrowLeft,
         faTrash,
         faPenToSquare,
         faCircleXmark,
         faChevronLeft,
         faMagnifyingGlass,
         faCircleCheck,
         faUser,
         faBell,
         faChartColumn,
         faPrint,
         faBars,
         faXmark,
         faAnglesRight,
         faAnglesLeft,
         faReply,
         faPhone,
         faBezierCurve,
         faPenSquare,
         faKey,
         faEnvelope,
         faAngleRight,
         faBook,
         faUserGroup,
         faCheckSquare
         
        
        } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faFacebookMessenger, faTelegram, faYoutube} from '@fortawesome/free-brands-svg-icons'

import {
 
  faSquare
  
} from "@fortawesome/free-regular-svg-icons";

library.add(
  
  faCheckSquare,
  faSquare,
  faUserGroup,
  faKey,
  faPenSquare,
    faDashboard,
    faChevronRight,
    faChevronDown,
    faList,
    faArrowRight,
    faArrowLeft,
    faTags,
    faUsers,
    faCircleInfo,
    faHouse,
    faTrash,
    faPenToSquare,
    faCircleXmark,
    faChevronLeft,
    faMagnifyingGlass,
    faCircleCheck,
    faUser,
    faBell,
    faChartColumn,
    faPrint,
    faBars,
    faXmark,
    faFacebook,
    faTelegram,
    faAnglesRight,
    faAnglesLeft,
    faReply,
    faPhone,
    faBezierCurve,
    faFacebookMessenger,
    faEnvelope,
    faYoutube,
    faAngleRight,
    faBook
)

// rich text editor
import CKEditor from '@ckeditor/ckeditor5-vue';

//firebase
// import { projectAuth } from './firebase/config'
// import { setPersistence,browserLocalPersistence } from 'firebase/auth'

// vue scroll picker 
import VueScrollPicker from "vue-scroll-picker";

const app = createApp(App);

const subdomain = window.location.host.split('.')[0];  //use to extract subdomain


let routes;
// Determine which router to use based on the subdomain
if (subdomain === 'admin') {
  routes =routerAdmin ; // Use admin page routes for the adminPage subdomain
} else {
  routes = routerClient; // Use client-side routes as default
}


setPersistence(projectAuth,browserLocalPersistence)
  .then(() => {
    app.component('font-awesome-icon', FontAwesomeIcon)
    app.use(routes)
    app.use(notivue)
    app.use(VueScrollPicker)
    app.use(VueNumerals)
    app.use(CKEditor)
    app.mount('#app')


  })
  .catch(error => {

    console.error('Error setting up session persistence:', error);
  });



